import { combineReducers } from 'redux'

import banners from './ui.banners'
import className from './ui.classname'
import currency from './ui.currency'
import menu from './ui.menu'
import relatedApps from './ui.related_apps'
import themeColor from './ui.theme_color'

export default combineReducers({
  banners,
  className,
  currency,
  menu,
  relatedApps,
  themeColor,
})
