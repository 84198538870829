import { StoreState } from '@app/store/store'
import { createThunk } from '@app/store/thunk'

import { setSelectorMeta } from './selector_meta.descriptors'

const keys: string[] = []

export const createSelectorMetaKey = <T>(key: string) => {
  if (keys.includes(key)) throw new Error(`Selector meta key ${key} already exists`)
  keys.push(key)

  return {
    selector: (state: StoreState) => state.selector_meta[key] as T | undefined,
    get: () => createThunk((_dispatch, getState) => getState().selector_meta[key] as T | undefined),
    set: (value: T) => setSelectorMeta({ key, data: value }),
    remove: () => setSelectorMeta({ key, data: undefined }),
  }
}
