export type ResultOk<T> = { error: false; value: T }
export type ResultError = { error: true; value: Error }
export type Result<T> = ResultOk<T> | ResultError

export function intoResult<T>(cb: () => T) {
  try {
    return resultOk(cb())
  } catch (e) {
    return resultError(intoError(e))
  }
}

export async function intoResultAsync<T>(val: T) {
  try {
    return resultOk(await val)
  } catch (e) {
    return resultError(intoError(e))
  }
}

export function resultOk<T>(value: T) {
  return { error: false, value } as ResultOk<T>
}

export function resultError(value: Error) {
  return { error: true, value } as ResultError
}

export function isOk<T>(val: Result<T>): val is ResultOk<T> {
  return !val.error
}

export function asOk<T>(val: Result<T>): ResultOk<T> | undefined {
  if (val.error) return undefined
  return val
}

export function isError<T>(val: Result<T>): val is ResultError {
  return val.error
}

export function asError<T>(val: Result<T>): ResultError | undefined {
  if (!val.error) return undefined
  return val
}

export function unwrapResult<T>(val: Result<T>): T {
  if (val.error) throw val.value
  return val.value
}

export function unwrapResults<T extends Result<any>[]>(...vals: T) {
  const errors: any[] = []
  for (const val of vals) {
    if (val.error) {
      errors.push(val.value)
    }
  }
  if (errors.length) throw new AggregateError(errors)
  return vals.map(v => v.value) as { [K in keyof T]: T[K] extends Result<infer R> ? R : never }
}

export function assertOk<T>(val: Result<T>): asserts val is ResultOk<T> {
  if (val.error) throw val.value
}

export function unwrapOr<T>(val: Result<T>, def: T): T {
  if (val.error) return def
  return val.value
}

export function intoError(e: unknown) {
  if (e instanceof Error) {
    return e
  }
  if (typeof e === 'string') {
    return new Error(e)
  }
  return new Error('Unexpected error object', { cause: e })
}
