import { getRequestsWithDebtDescriptor, postRequestsWithDebtRepayDescriptor } from '@app/store/actions/api/requests.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[] | null>(null, builder => {
  builder.addCase(getRequestsWithDebtDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data.map(req => req.id)
  })
  builder.addCase(postRequestsWithDebtRepayDescriptor.shapes.fulfilled, (_state, _action) => {
    return []
  })
})
