import uniqBy from 'lodash/unionBy'

import { ApiSocialIdentity } from '@app/constants/ApiTypes/entities'

import { deleteIdentitiesByIdDescriptor, getIdentitiesDescriptor, postIdentitiesDescriptor } from '@app/store/actions/api/identities.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiSocialIdentity[] | null>(null, builder => {
  builder.addCase(getIdentitiesDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })

  builder.addCases([postIdentitiesDescriptor.shapes.fulfilled], (state, action) => {
    if (!state) return state
    return uniqBy([...state, action.payload.data], i => i.id)
  })

  builder.addCase(deleteIdentitiesByIdDescriptor.shapes.fulfilled, (state, action) => {
    if (!state) return state
    return state.filter(identity => identity.id !== action.meta.id)
  })
})
