import type { RelatedApp } from '@app/utils/getInstalledRelatedApps'

import { createAction, createPayloadlessAction } from '@app/store/toolkit'
import type { BannersState } from '@app/store/types/banners'

export const uiSetBannersState = createAction<'UI_SET_BANNERS_STATE', Partial<BannersState>>('UI_SET_BANNERS_STATE')
export const uiClearBannersState = createPayloadlessAction('UI_CLEAR_BANNERS_STATE')
export const setThemeColor = createAction<'SET_THEME_COLOR', string>('SET_THEME_COLOR')
export const addClassName = createAction<'ADD_CLASSNAME', string>('ADD_CLASSNAME')
export const removeClassName = createAction<'REMOVE_CLASSNAME', string>('REMOVE_CLASSNAME')
export const setRelatedApps = createAction<'SET_RELATED_APPS', RelatedApp[]>('SET_RELATED_APPS')
