import React, { Suspense } from 'react'
import type Cookies from 'universal-cookie'

import { StaticRedirect } from '@app/utils/routing/StaticRedirect'
import { Action, History, LocationDescriptorObject, MatchedRoute, RouterInterface } from '@app/utils/routing/types'

import { Store } from '@app/store/store'

import { AppErrorBoundary } from '@app/components/AppErrorBoundary/AppErrorBoundary'
import { ContextProvider } from '@app/components/ContextProvider/ContextProvider'

export default async function ({
  router: Router,
  store,
  cookies,
  afterRender,
}: {
  router: RouterInterface
  history: History
  onRouteChange?: (location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]) => Promise<StaticRedirect | void>
  store: Store
  cookies: Cookies
  afterRender?: (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[]) => Promise<unknown>
  handleError?: (e: unknown) => void
  performFetch?: boolean
}) {
  return (
    <S>
      <ContextProvider cookies={cookies} store={store}>
        <AppErrorBoundary>
          <Router afterRender={afterRender} />
        </AppErrorBoundary>
      </ContextProvider>
    </S>
  )
}

const S = IS_BROWSER ? Suspense : React.Fragment
