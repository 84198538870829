import { createSelector } from 'reselect'

import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { fetchInitialNews } from '@app/store/actions/news'
import { createSelectorMetaKey } from '@app/store/actions/selector_meta'
import { StoreState } from '@app/store/store'

import { profileUserResultSelector } from './profile'

export const newsMeta = createSelectorMetaKey<{ user_id: string }>('News')
export const newsStateMeta = createSelectorMetaKey<{ loadedAt: number; page: number; total_pages: number }>('News state')
export const newsLoadingMeta = createSelectorMetaKey<{ loading: boolean }>('News loading')

export const newsResultSelector = createSelector([newsMeta.selector, profileUserResultSelector, (state: StoreState) => state.news], (meta, userResult, list) =>
  intoResult(() => {
    const user = unwrapResult(userResult)
    if (!user || user.account_type === 'visitor') return []
    if (!meta || meta.user_id !== user.id) {
      throw ActionRequiredError.create('News must be fetched', user.id, async dispatch => {
        const resp = await dispatch(fetchInitialNews()).then(assertApiActionResponse('News fetch failed'))
        if (!resp) return
        dispatch(newsMeta.set({ user_id: user.id }))
      })
    }
    if (!list) throw new Error('News are missing')
    return list
  })
)
export const newsUnreadCountResultSelector = createSelector([newsResultSelector], articlesResult =>
  intoResult(() => {
    const articles = unwrapResult(articlesResult)
    return articles.filter(a => !a.attributes.read).length
  })
)

export const newsHasMoreSelector = createSelector([newsStateMeta.selector], meta => {
  if (!meta) return false
  return meta.page < meta.total_pages
})
export const newsLoadingSelector = createSelector([newsLoadingMeta.selector], meta => {
  if (!meta) return false
  return meta.loading
})
