import { NavigationType } from 'react-router'

import createRoutes from '@app/routes_list/routes_list'

import { performFetchData } from '@app/utils/performFetchData'
import { normalizePath } from '@app/utils/url'

import { withProgress } from '@app/store/actions/initial'
import { setPlaceByRegion } from '@app/store/actions/profile'
import { profilePlaceStateSelector, profileRegionIsChangeableSelector, profileRegionSlugSelector } from '@app/store/selectors/profile'
import { availableRegionsSlugsSelector, defaultRegionSelector } from '@app/store/selectors/regions'
import { createThunk } from '@app/store/thunk'

import { matchRoutes } from './functions'
import { getRedirect, RedirectLocation } from './Redirect'
import { getRegionFromPath, matchRegion, trimRegionalRoute } from './region'
import { StaticRedirect } from './StaticRedirect'
import { Action, History, LocationDescriptor, LocationDescriptorObject, MatchedRoute, Route, RouterState } from './types'

export const processRoutes = ({
  location,
  action,
  initial,
  performFetch,
  beforeNavigate,
  onNavigate,
  signal,
}: {
  location: History['location']
  action: History['action']
  initial: boolean
  performFetch: boolean
  beforeNavigate?: (state: RouterState) => unknown
  onNavigate?: (state: RouterState) => unknown
  signal?: AbortSignal
}) =>
  createThunk(async (dispatch, getState) => {
    const regions = availableRegionsSlugsSelector(getState())
    const routes = await dispatch(createRoutes(location))
    const routingState = { routes, location, action }
    if (signal?.aborted) return

    const matchedRoutes = matchRoutes(regions, routingState.routes, routingState.location.pathname)
    const match = matchedRoutes.at(-1)

    const navigationType = actionToNavigationType(routingState.action)

    const routerState: RouterState = { ...match!, location, action: navigationType }
    const currentState = getState().routing.state
    const isSameRoute = currentState && currentState.route.event_id === routerState.route.event_id
    if (!isSameRoute) {
      await beforeNavigate?.(routerState)
    }

    if (performFetch && match) {
      {
        const redirectDescriptor = getRedirect(match.route.component)
        if (redirectDescriptor) {
          const redirectLocation = getLocation(location, redirectDescriptor?.to ?? null)
          if (redirectLocation) return { type: 'redirect' as const, redirect: new StaticRedirect(redirectLocation, redirectDescriptor?.permanent ? 301 : 302) }
        }
      }

      {
        const loadResult = await dispatch(loadRouteData(initial, performFetch, routingState.location, navigationType, matchedRoutes))
        if (signal?.aborted) return

        if (loadResult.type === 'redirect') return loadResult
      }
    }

    if (signal?.aborted) return

    const scroll = isSameRoute ? 'save' : routingState.action === 'POP' ? 'save' : routingState.location.state && routingState.location.state.scroll

    if (!isSameRoute) {
      await onNavigate?.(routerState)
    }

    return {
      type: 'routerState' as const,
      scroll,
      state: {
        routes: routingState.routes,
        matchedRoutes,
        location: routingState.location,
        action: navigationType,
        routerState,
      },
    }
  })

const loadRouteData = (initial: boolean, performFetch: boolean, location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]) =>
  createThunk(async (dispatch): Promise<{ type: 'navigation'; state: RouterState } | { type: 'redirect'; redirect: StaticRedirect }> => {
    const match = matchedRoutes.at(-1) ?? null
    const showProgress = !initial && match?.route.progress !== false && location.state?.progress !== false
    const routerState = { ...match!, location, action }

    let promise = dispatch(processMatchedRoutes(performFetch, location, action, matchedRoutes))
    if (showProgress) promise = dispatch(withProgress(promise))
    const redirect = await promise

    if (redirect) return { type: 'redirect', redirect }

    return { type: 'navigation', state: routerState }
  })

const processMatchedRoutes = (performFetch: boolean, location: LocationDescriptorObject, action: Action, matchedRoutes: MatchedRoute[]) =>
  createThunk(async (dispatch, getState) => {
    const error = getState().error

    if (error) return

    const regionRedirect = await dispatch(performRegionCheck(location, matchedRoutes))
    if (regionRedirect) return regionRedirect

    if (performFetch) {
      const fetchDataRedirect = await dispatch(performFetchData(location, action, matchedRoutes))
      if (fetchDataRedirect) return fetchDataRedirect
    }
  })

export const performRegionCheck = (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[]) => {
  return createThunk(async dispatch => {
    return await processRoutesData(matchedRoutes, async ({ route }) => {
      const result = dispatch(checkRegionDifference(route, location))
      if (result?.type === 'promise') return { ...result, stop: true }
      return result ?? undefined
    })
  })
}

const checkRegionDifference = (route: Route, location: LocationDescriptorObject) =>
  createThunk((dispatch, getState): { type: 'promise'; promise: () => Promise<void> } | { type: 'redirect'; redirect: StaticRedirect } | null => {
    if (!route.regional) return null

    const state = getState()
    const placeState = profilePlaceStateSelector(state)
    const manageRegion = profileRegionIsChangeableSelector(state)
    const profileRegionSlug = profileRegionSlugSelector(state)
    const regionsList = availableRegionsSlugsSelector(state)
    const pathRegionSlug = getRegionFromPath(regionsList, location.pathname ?? '')

    if (!manageRegion) {
      if (pathRegionSlug) {
        return {
          type: 'redirect',
          redirect: new StaticRedirect({
            ...location,
            pathname: normalizePath(`/${trimRegionalRoute(regionsList, location.pathname ?? '')}`),
            state: { ...location.state, scroll: 'save' },
          }),
        }
      }
      return null
    }

    if (profileRegionSlug === pathRegionSlug) return null

    if (!pathRegionSlug || placeState.change) {
      return {
        type: 'redirect',
        redirect: new StaticRedirect({
          ...location,
          pathname: normalizePath(`/${profileRegionSlug ?? ''}${trimRegionalRoute(regionsList, location.pathname ?? '')}`),
          state: { ...location.state, scroll: 'save' },
        }),
      }
    }

    return { type: 'promise', promise: () => dispatch(setPlaceByRegionSlug(pathRegionSlug)) }
  })

const setPlaceByRegionSlug = (slug: string) =>
  createThunk(async (dispatch, getState) => {
    const state = getState()
    const regionsList = availableRegionsSlugsSelector(state)
    const defaultRegion = defaultRegionSelector(state)
    const matchedSlug = matchRegion(regionsList, slug)
    const region = !matchedSlug ? defaultRegion : Object.values(getState().regions.models).find(r => r.attributes.slug === matchedSlug)
    if (!region) throw new Error(`Can't find region: ${region}`)

    return dispatch(setPlaceByRegion(region.id))
  })

type RouteResult = { type: 'promise'; promise: () => Promise<void>; stop?: boolean } | { type: 'redirect'; redirect: StaticRedirect }

const getLocation = (currentLocation: LocationDescriptorObject, location: RedirectLocation): LocationDescriptor | null => {
  if (typeof location === 'function') return location(currentLocation)
  return location
}

const actionToNavigationType = (action: 'POP' | 'PUSH' | 'REPLACE'): NavigationType => {
  switch (action) {
    case 'POP':
      return NavigationType.Pop
    case 'REPLACE':
      return NavigationType.Replace
    case 'PUSH':
      return NavigationType.Push
  }
}

export async function processRoutesData(
  routes: MatchedRoute<any>[],
  action: (route: MatchedRoute) => Promise<RouteResult | void>
): Promise<StaticRedirect | void> {
  for (const route of routes) {
    const result = await action(route)
    if (result?.type === 'redirect') return result.redirect

    if (result?.type === 'promise') {
      await result.promise()
      if (result.stop) return
    }
  }
}
