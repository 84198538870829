import { ApiKeyValue } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getKeyValuesByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('getKeyValuesById').setShape<ApiDataResponse<ApiKeyValue>, { id: string }>()

export const putKeyValuesByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putKeyValuesById')
  .setShape<unknown, { id: string; body: { value: string } }>()
