import { createContext, useContext } from 'react'

export type StackContextValue = {
  stackId: string
  parent?: StackContextValue
  store: Record<string | symbol, any>
  abortController: AbortController
  handleAction: <P>(promise: Promise<P>) => Promise<P>
}

const StackContext = createContext<StackContextValue>(null as any)

export const StackContextProvider = StackContext.Provider

export const useStackContext = () => {
  const ctx = useContext(StackContext)
  if (!ctx) throw new Error('StackContextProvider is missing')
  return ctx
}
