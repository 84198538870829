import { SLUGLESS_REGIONS } from './routing/region'

export interface RegionExtensionData {
  /** latitude, longitude */
  zIndex: number
  keywords: string[]
  averageIncomeRates?: { min: number; max: number }
}

const createRegions = <K extends string>(obj: Record<K, RegionExtensionData>): Record<K, RegionExtensionData> => obj

const enum ZINDEX {
  world = 0,
  country = 1,
  region = 2,
  city = 3,
}

/**
 * Map of Kidsout cities
 */
export const RegionsExtensionData = createRegions({
  msk: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в москве', 'няня в москве'],
    // "sample_ip": "178.219.186.12"
  },
  spb: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в санкт-петербурге', 'бебиситтер в питере', 'няня в санкт-петербурге', 'няня в питере'],
    // "sample_ip": "109.205.253.39"
  },
  tmn: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в тюмени', 'няня в тюмени'],
    // "sample_ip": "188.186.65.122"
  },
  ekb: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в екатеринбурге', 'бебиситтер в екате', 'няня в екатеринбурге', 'няня в екате'],
    // "sample_ip": "195.19.132.64"
  },
  nsk: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в новосибирске', 'бебиситтер в новосибе', 'няня в новосибирске', 'няня в новосибе'],
    // "sample_ip": "195.19.132.64"
  },
  russia: {
    zIndex: ZINDEX.country,
    keywords: [],
    // "sample_ip": "195.19.132.64"
  },
  world: {
    zIndex: ZINDEX.world,
    keywords: [],
    // "sample_ip": "195.19.132.64"
  },
  rnd: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в ростове-на-дону', 'няня в ростове-на-дону'],
    // "sample_ip": "195.19.132.64"
  },
  oren: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в оренбурге', 'няня в оренбурге'],
    // "sample_ip": "195.19.132.64"
  },
  krasnoyarsk: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в красноярске', 'няня в красноярске'],
    // "sample_ip": "195.19.132.64"
  },
  sochi: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в сочи', 'няня в сочи'],
  },
  tomsk: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в томске', 'няня в томске'],
  },
  kld: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в калининграде', 'няня в калининграде'],
  },

  georgia: {
    zIndex: ZINDEX.country,
    keywords: ['бебиситтер в грузии', 'няня в грузии'],
  },
  armenia: {
    zIndex: ZINDEX.country,
    keywords: ['бебиситтер в армении', 'няня в армении'],
  },
  turkey: {
    zIndex: ZINDEX.country,
    keywords: ['бебиситтер в турции', 'няня в турции'],
  },
  azerbaijan: {
    zIndex: ZINDEX.country,
    keywords: ['бебиситтер в азербайджане', 'няня в азербайджане'],
  },
  bulgaria: {
    zIndex: ZINDEX.country,
    keywords: ['бебиситтер в болгарии', 'няня в болгарии'],
  },
  kz: {
    zIndex: ZINDEX.country,
    keywords: ['бебиситтер в казахстане', 'няня в казахстане'],
    averageIncomeRates: { min: 700, max: 4000 },
  },
  almaty: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в алматы', 'няня в алматы'],
    averageIncomeRates: { min: 700, max: 4000 },
  },
  astana: {
    zIndex: ZINDEX.city,
    keywords: ['бебиситтер в астане', 'няня в астане', 'няня в нур-султане', 'бебиситтер в нур-султане'],
    averageIncomeRates: { min: 700, max: 4000 },
  },
})

export const isRegionAvailable = (r: { available: boolean; slug: string }) => r.available && (r.slug === 'msk' || !SLUGLESS_REGIONS.includes(r.slug))
export const isRegionHasRoute = (r: { available: boolean; slug: string }) => isRegionAvailable(r) && r.slug !== 'msk'
