import React, { createContext, CSSProperties, FunctionComponent, lazy, ReactNode, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { noop } from '@app/utils/noop'
import { useOverflowContext } from '@app/utils/OverflowContext'
import { useAppDispatch } from '@app/utils/redux'
import { useRouter } from '@app/utils/routing/hooks'
import { trimRegionalRoute } from '@app/utils/routing/region'
import { normalizePath } from '@app/utils/url'

import { useSelectorResultValue } from '@app/packages/selectorResult/useSelectorResult'

import { useDeviceType } from '@app/hooks/useDeviceType'

import { requireTOSAccept } from '@app/store/actions/requireTosAccept'
import { isAppSelector } from '@app/store/selectors/misc'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'

import { Footer } from './Footer'
import { Header, HeaderProps } from './Header'
import { createNavItemsSelector } from './navItemsSelector'
import { matchCurrentItem } from './navItemsUtils'
import { SideMenuLayout } from './SideMenuLayout'

import './LayoutMilk.scss'

export interface LayoutMilkProps {
  className?: string
  /** event name for page in kebab-case */
  page: string
  isApp?: boolean
  header?: Partial<HeaderProps> | false
  footer?: boolean
  banners?: boolean
  sideMenu?: boolean
  /** whether overflow context should be increased */
  fixed?: boolean
  /** whether content after header should be pushed out of header */
  offset?: boolean
  /**
   * (default: true)
   * if true then layout becomes flex layout with direction: column and content gets wrapped in flex item with "flex: 1".
   * If false layout uses a hack of "height: 100%". This way layout and all its parent elements has css "height: 100%".
   * So if you use IntroWrapper which relies on this hack "flex" is undesireable.
   *
   * In short "height: 100%" is hack for iPhone to create block of 100% height. Using 100vh on block will result height
   * slightly bigger than 100% as it takes height of browser controls.
   */
  flex?: boolean
  /** what background color of layout should be */
  color?: 'gray'
  /**
   * children can be a function which receives headerHeight function.
   * Useful when you put absolutely posiioned element and want to know header height.
   * Right now used in NewsAndMessages layout to render chat
   */
  children: ReactNode | ((data: ChildrenRendererProps) => ReactNode)
}

/** Third version of kidsout layout named Milk */
export const LayoutMilk: FunctionComponent<LayoutMilkProps> & {
  classes: typeof classes
} = ({
  className,
  page,
  isApp: localIsApp,
  header = {},
  footer = true,
  banners = true,
  sideMenu = true,
  offset = true,
  flex = true,
  fixed = false,
  color,
  children,
}) => {
  const router = useRouter()
  const overflowContext = useOverflowContext()
  const dispatch = useAppDispatch()
  const device = useDeviceType()

  const headerRef = useRef<HTMLDivElement>(null)
  const headerMainRef = useRef<HTMLDivElement>(null)

  const globalIsApp = useSelector(isAppSelector)
  const regions = useSelector(availableRegionsSlugsSelector)

  const isApp = localIsApp || globalIsApp

  const [headerOffset, setHeaderOffset] = useState<null | number>(headerHeight)
  const [topHeaderOffset, setTopHeaderOffset] = useState<number>(topHeaderHeight)
  const [bannersCount, setBannersCount] = useState(0)

  const pathname = trimRegionalRoute(regions, normalizePath(router.location.pathname))

  const showSecondaryMenu = header && !header.showSecondary ? false : true

  const navItemsSelector = useMemo(() => createNavItemsSelector(() => router.location.pathname), [router.location.pathname])
  const navItems = useSelectorResultValue(navItemsSelector)

  const bannersBlock =
    !isApp && banners ? (
      <Suspense>
        <Banners onBannerCountChange={setBannersCount} />
      </Suspense>
    ) : null

  const currentNavItems = useMemo(() => matchCurrentItem(pathname, navItems), [navItems, pathname])

  const headerOffsetStyle = useMemo<CSSProperties>(() => {
    if (IS_BROWSER && headerOffset) return { height: headerOffset + 'px' }
    return {}
  }, [headerOffset])

  const hasSecondRow = !!currentNavItems[0]?.children?.length

  const childrenProps = useMemo<ChildrenRendererProps>(() => {
    if (isApp) return { headerHeight: 0, topHeaderHeight: 0 }
    const height = IS_BROWSER && headerOffset ? headerOffset : hasSecondRow ? INITIAL_HEADER_HEIGHT + INITIAL_SUBHEADER_HEIGHT : INITIAL_HEADER_HEIGHT
    return { headerHeight: height, topHeaderHeight: topHeaderOffset }
  }, [hasSecondRow, headerOffset, isApp, topHeaderOffset])

  /* effects */

  useEffect(() => {
    let running = true

    requestAnimationFrame(function runner() {
      if (!running) return
      const headerEl = headerRef.current
      if (headerEl) {
        const height = headerEl.clientHeight
        headerHeight = height
        setHeaderOffset(height)
      } else {
        setHeaderOffset(0)
      }
      const headerMainEl = headerMainRef.current
      if (headerMainEl) {
        const topHeight = headerMainEl.clientHeight
        topHeaderHeight = topHeight
        setTopHeaderOffset(topHeight)
      } else {
        setTopHeaderOffset(0)
      }
      requestAnimationFrame(runner)
    })

    const timeout = setTimeout(() => {
      running = false
    }, 500)

    return () => {
      running = false
      clearTimeout(timeout)
    }
  }, [bannersCount, hasSecondRow, showSecondaryMenu, device])

  useEffect(() => {
    if (TOSModalOpened) return
    TOSModalOpened = true
    dispatch(requireTOSAccept())
      .catch(noop)
      .then(() => {
        TOSModalOpened = false
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!fixed) return

    overflowContext.increment()
    return () => {
      overflowContext.decrement()
    }
  }, [fixed, overflowContext])

  /* render */

  const childrenContent = (
    <LayouMilkContext.Provider value={childrenProps}>{typeof children === 'function' ? children(childrenProps) : children}</LayouMilkContext.Provider>
  )

  const content = (
    <section
      className={cn(
        ControlClasses.max_height,
        'layout-milk',
        `layout-milk--color-${color || 'white'}`,
        {
          'layout-milk--flex': flex,
          'layout-milk--no-header': !header,
          'layout-milk--with-secondary': !!header && !!currentNavItems[0]?.children,
          ['m-' + page]: page,
        },
        className
      )}
    >
      <div className="layout-milk__header" ref={headerRef}>
        {!isApp && <div className={cn('layout-milk__fixed-banners')}>{bannersBlock}</div>}
        {header && (
          <Header
            {...header}
            currentMainItem={currentNavItems[0] ?? undefined}
            currentSecondaryItem={currentNavItems[1] ?? undefined}
            items={navItems}
            mainRef={headerMainRef}
          />
        )}
      </div>
      {offset && <div className="layout-milk__header-offset" style={headerOffsetStyle} />}

      {flex ? <div className="layout-milk__content">{childrenContent}</div> : childrenContent}

      {footer && !isApp && <Footer navItems={navItems} />}
    </section>
  )

  if (!sideMenu || isApp) return content

  return (
    <SideMenuLayout currentItems={currentNavItems} items={navItems}>
      {content}
    </SideMenuLayout>
  )
}

type LayouMilkContextDescriptor = { headerHeight: number; topHeaderHeight: number }

const LayouMilkContext = createContext<LayouMilkContextDescriptor>({ headerHeight: 0, topHeaderHeight: 0 })

export const LayouMilkContextConsumer = LayouMilkContext.Consumer

export const useLayoutMilkContext = () => useContext(LayouMilkContext)

/**
 * "grow" is flex: 1, so section takes all space. Use in conjunction with "flex={true}" prop on Layout. Otherwise it has no effect.
 */
type SectionModifier = 'grow' | 'tall' | 'color' | 'color-gray' | 'color-blue' | 'decorated' | 'decorated-left' | 'decorated-right'

const classes = {
  /**
   * adds block of header height,
   * so content appears undex header
   */
  section: (...modifiers: SectionModifier[]) => ['layout-milk__section', ...modifiers.map(m => `layout-milk__section--${m}`)].join(' '),
  /**
   * limits width of section to 1000px,
   * must be used inside section
   */
  sectionWrapper: 'layout-milk__wrapper',
  sectionWrapperNarrow: 'layout-milk__wrapper-narrow',
  /** h1 title */
  sectionTitle: 'layout-milk__section-title',
  /** h2 title */
  sectionSubtitle: 'layout-milk__section-subtitle',
  sectionCutter: 'layout-milk__section-cutter',
  sectionText: 'layout-milk__section-text',
  sectionCols: 'layout-milk__section-cols',
  sectionColsItem: 'layout-milk__section-cols-item',
}

LayoutMilk.classes = classes

let TOSModalOpened: boolean = false

const INITIAL_HEADER_HEIGHT = 72
const INITIAL_SUBHEADER_HEIGHT = 54

let headerHeight: null | number = null
let topHeaderHeight: number = INITIAL_HEADER_HEIGHT

type ChildrenRendererProps = { headerHeight: number; topHeaderHeight: number }

const Banners = lazy(() => import('./Banners').then(m => ({ default: m.Banners })))
