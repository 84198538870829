import { defineMessages } from 'react-intl'
import { createSelector } from 'reselect'

import { ApiOnboardingStepTraining } from '@app/constants/ApiTypes/entities'
import { SitterStatus } from '@app/constants/ApiTypes/sitterStatus'

import { assertNever } from '@app/utils/assertNever'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getSitterOnboarding } from '@app/store/actions/api/sitter_onboarding'
import { createSelectorMetaKey } from '@app/store/actions/selector_meta'
import {
  approvedBannerSeenStateKeyValue,
  onboardingApplicationStepsSeenStateKeyValue,
  onboardingTestPassedSeenStateKeyValue,
  onboardingTrainingPassedSeenKeyValue,
} from '@app/store/actions/sitter_onboarding'
import { StoreState } from '@app/store/store'

import { contactsListResultSelector } from './contacts'
import { onboardingV2EnabledResultSelector } from './features'
import { intlSelector } from './misc'
import { profileUserResultSelector } from './profile'
import { schoolModelsSelector } from './schools'

export const onboardingMeta = createSelectorMetaKey<{ user_id: string }>('Sitter onboarding v2')

const onboardingResultSelector = createSelector(
  [onboardingMeta.selector, profileUserResultSelector, onboardingV2EnabledResultSelector, (state: StoreState) => state.sitter_onboarding],
  (meta, userResult, enabledResult, onboarding) =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (!user) throw new Error('User is missing')
      if (user.account_type !== 'sitter') throw new Error('User is not sitter')
      const enabled = unwrapResult(enabledResult)
      if (!enabled) throw new Error('Onboarding v2 is not enabled')
      if (!meta || meta.user_id !== user.id) {
        throw ActionRequiredError.create('Onboarding must be fetched', user.id, async dispatch => {
          await dispatch(getSitterOnboarding()).then(assertApiActionResponse('Onboarding load failed'))
          dispatch(onboardingMeta.set({ user_id: user.id }))
        })
      }
      if (!onboarding) throw new Error('Onboarding is missing')
      return onboarding
    })
)
const onboardingStepsSelector = (state: StoreState) => state.sitter_onboarding_steps

export const sitterStatusV2Selector = createSelector(
  [
    onboardingV2EnabledResultSelector,
    profileUserResultSelector,
    onboardingResultSelector,
    onboardingStepsSelector,
    onboardingApplicationStepsSeenStateKeyValue.selector,
    onboardingTestPassedSeenStateKeyValue.selector,
    onboardingTrainingPassedSeenKeyValue.selector,
    approvedBannerSeenStateKeyValue.selector,
    schoolModelsSelector,
    contactsListResultSelector,
    intlSelector,
  ],
  (
    enabledResult,
    userResult,
    onboardingResult,
    onboardingSteps,
    stepsSeenResult,
    testPassedSeenResult,
    trainingPassedSeenResult,
    approvedBannerSeenResult,
    schools,
    contactsResult,
    intl
  ) =>
    intoResult((): SitterStatus => {
      const { formatMessage } = intl
      const user = unwrapResult(userResult)
      if (user?.account_type !== 'sitter') throw new Error('User is not sitter')
      const enabled = unwrapResult(enabledResult)
      if (!enabled) return { type: 'v2_disabled' }
      if (user.approved) {
        const approvedBannerSeen = unwrapResult(approvedBannerSeenResult)
        return { type: 'approved', banner_visible: !approvedBannerSeen.value?.seen }
      }
      if (typeof user.age === 'number' && user.age < 18) return { type: 'application_on_hold', reason: 'underage', min_age: 18 }

      const onboarding = unwrapResult(onboardingResult)
      if (!onboarding.relationships.steps.data) throw new Error('No onboarding relationships')
      const steps = onboarding.relationships.steps.data.map(d => {
        const step = onboardingSteps[d.id]
        if (!step) throw new Error(`Step ${d.id} not found`)
        return step
      })

      const stepsSeen = unwrapResult(stepsSeenResult)
      if (!stepsSeen.value?.seen) return { type: 'application_accepted' }

      const contacts = unwrapResult(contactsResult)
      const contact = (() => {
        const region_id = String(user.region_id)
        const c =
          contacts.find(c => c.attributes.department === 'school' && c.region && c.region.id === region_id) ||
          contacts.find(c => c.attributes.department === 'school' && !c.region)
        if (!c) return null
        const result = {
          whatsapp: c.attributes.whatsapp ?? undefined,
          telegram: c.attributes.telegram ?? undefined,
        }
        return result
      })()

      const hasRejectedStep = steps.find(s => {
        if (s.attributes.name === 'testing' && s.attributes.status === 'failed') return true
        if (s.attributes.name === 'training' && s.attributes.status === 'rejected') return true
        if (s.attributes.name === 'profile' && s.attributes.status === 'disapproved') return true
        return false
      })

      if (hasRejectedStep) {
        const trainingStep = steps.find((s): s is ApiOnboardingStepTraining => s.attributes.name === 'training')
        if (!trainingStep) throw new Error('Training step is missing')
        const feedbackLink = trainingStep.attributes.feedback_link
        return {
          type: 'application_rejected',
          feedback_link: feedbackLink,
          refund: hasRejectedStep.attributes.name === 'training' ? { refund_duration_days: 10 } : undefined,
        }
      }

      for (const step of steps) {
        const name = step.attributes.name
        switch (name) {
          case 'testing': {
            const status = step.attributes.status
            switch (status) {
              case 'pending':
                return {
                  type: 'testing_required',
                  test_link: step.attributes.psych_test_link,
                  contacts: {
                    telegram: contact?.telegram,
                    whatsapp: contact?.whatsapp,
                  },
                }
              case 'failed':
                throw new Error('Unexpected testing status failed')
              case 'passed': {
                const testPassedSeen = unwrapResult(testPassedSeenResult)
                if (!testPassedSeen.value?.seen) {
                  return {
                    type: 'testing_passed',
                    telegram_link: applyText(contact?.telegram, formatMessage(messages.want_to_know_test_results)),
                    whatsapp_link: applyText(contact?.whatsapp, formatMessage(messages.want_to_know_test_results)),
                  }
                }
                continue
              }
              default:
                return assertNever(status, () => new Error(`Unexpected testing status ${status}`))
            }
          }
          case 'training': {
            const status = step.attributes.status
            switch (status) {
              case 'not_selected': {
                return {
                  type: 'training_required',
                  programs: [{ id: 'offline', link: '/school_program.pdf', name: formatMessage(messages.offline) }],
                  telegram_link: applyText(contact?.telegram, formatMessage(messages.want_to_choose_school_dates)),
                  whatsapp_link: applyText(contact?.whatsapp, formatMessage(messages.want_to_choose_school_dates)),
                }
              }
              case 'selected': {
                const schoolId = (step as ApiOnboardingStepTraining).relationships.school.data?.id
                if (!schoolId) throw new Error('No school id')
                const school = schools[schoolId]
                if (!school) throw new Error(`School ${schoolId} not found`)
                const online = school.attributes.format.includes('online')
                return {
                  type: 'training_pending',
                  training: online
                    ? {
                        type: 'online',
                        date_start: school.attributes.start_date,
                        date_end: school.attributes.end_date,
                      }
                    : {
                        type: 'offline',
                        date_start: school.attributes.start_date,
                        date_end: school.attributes.end_date,
                        address: school.attributes.address,
                      },
                  telegram_link: applyText(contact?.telegram, formatMessage(messages.question_regarding_the_school)),
                  whatsapp_link: applyText(contact?.whatsapp, formatMessage(messages.question_regarding_the_school)),
                }
              }
              case 'rejected':
                throw new Error('Unexpected training status rejected')
              case 'finished': {
                const trainingPassedSeen = unwrapResult(trainingPassedSeenResult)
                if (!trainingPassedSeen.value?.seen) return { type: 'training_passed' }
                continue
              }
              default:
                return assertNever(status, () => new Error(`Unexpected training status ${status}`))
            }
          }
          case 'profile': {
            const status = step.attributes.status
            switch (status) {
              case 'pending': {
                const profileIncomplete = Object.entries(step.attributes.completeness)
                  .filter(([key, _value]) => key !== 'schedule')
                  .some(([_key, value]) => !value)
                return { type: 'profile_complete_required', schedule_completed: step.attributes.completeness.schedule, profile_completed: !profileIncomplete }
              }
              case 'in_review':
                return { type: 'profile_approval_required' }
              case 'approved': {
                const approvedBannerSeen = unwrapResult(approvedBannerSeenResult)
                return { type: 'approved', banner_visible: !approvedBannerSeen.value?.seen }
              }
              case 'disapproved':
                return { type: 'profile_rejected' }
              default:
                return assertNever(status, () => new Error(`Unexpected profile status ${status}`))
            }
          }
          default:
            return assertNever(name, () => new Error(`Unexpected onboarding step ${name}`))
        }
      }

      throw new Error('Cannot extract sitter onboarding status')
    })
)

export const sitterCandidateRoutingStatusSelector = createSelector([profileUserResultSelector, sitterStatusV2Selector], (userResult, v2statusResult) =>
  intoResult(
    (): null | {
      main: 'registration' | 'settings'
      schedule_available: boolean
      profile_available: boolean
      messages_available: boolean
    } => {
      const user = unwrapResult(userResult)
      if (!user) return null

      if (user.account_type !== 'sitter') return null
      if (user.approved) return null

      const v2status = unwrapResult(v2statusResult)
      if (v2status.type === 'v2_disabled') {
        if (!user.training_completed) {
          return {
            main: 'registration' as const,
            schedule_available: false,
            profile_available: false,
            messages_available: false,
          }
        }

        return {
          main: 'settings' as const,
          schedule_available: true,
          profile_available: true,
          messages_available: true,
        }
      }

      if (v2status.type === 'approved') return null

      const settingsAvailable = (() => {
        switch (v2status.type) {
          case 'profile_complete_required':
          case 'profile_approval_required':
          case 'profile_rejected':
            return true
          case 'application_accepted':
          case 'application_rejected':
          case 'application_on_hold':
          case 'testing_required':
          case 'testing_passed':
          case 'training_required':
          case 'training_pending':
          case 'training_passed':
          default:
            return false
        }
      })()

      const mainMode = (() => {
        switch (v2status.type) {
          case 'profile_complete_required':
          case 'profile_approval_required':
          case 'profile_rejected':
            return 'settings' as const
          case 'application_accepted':
          case 'application_rejected':
          case 'application_on_hold':
          case 'testing_required':
          case 'testing_passed':
          case 'training_required':
          case 'training_pending':
          case 'training_passed':
          default:
            return 'registration' as const
        }
      })()

      return {
        main: mainMode,
        schedule_available: settingsAvailable,
        profile_available: settingsAvailable,
        messages_available: settingsAvailable,
      }
    }
  )
)

const applyText = (url: string | undefined, text: string) => {
  if (!url) return undefined
  return `${url}?text=${encodeURIComponent(text)}`
}

const messages = defineMessages({
  offline: 'Офлайн',
  want_to_know_test_results: 'Хочу узнать результаты теста',
  want_to_choose_school_dates: 'Хочу выбрать даты школы',
  question_regarding_the_school: 'У меня есть вопрос по школе',
})
