import uniqBy from 'lodash/uniqBy'

import { ApiTelegramBotConnection } from '@app/constants/ApiTypes/entities'

import {
  deleteTelegramBotConnectionsDescriptor,
  getTelegramBotConnectionsDescriptor,
  postTelegramBotConnectionsDescriptor,
} from '@app/store/actions/telegram.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiTelegramBotConnection[] | null>(null, builder => {
  builder.addCase(getTelegramBotConnectionsDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })

  builder.addCase(postTelegramBotConnectionsDescriptor.shapes.fulfilled, (state, action) => {
    if (!state) return state
    return uniqBy([action.payload.data, ...state], t => t.id)
  })

  builder.addCase(deleteTelegramBotConnectionsDescriptor.shapes.fulfilled, (state, action) => {
    if (!state) return state
    return state.filter(t => t.id !== action.meta.id)
  })
})
