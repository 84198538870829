import { ApiPromotedSitter } from '@app/constants/ApiTypes/entities'

import { getSittersPromotedDescriptor } from '@app/store/actions/api/sitters.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiPromotedSitter[] | null>(null, builder => {
  builder.addCase(getSittersPromotedDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
