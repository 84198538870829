import { asError } from '@app/utils/asError'

import { Result, resultError, resultOk } from '@app/packages/Result/Result'

import { createThunk, ThunkAction } from '@app/store/thunk'

export class ActionRequiredError extends Error {
  private action!: ThunkAction<Promise<void>>
  private promise: Promise<void> | undefined
  /** key to distinct vary */
  key!: string
  resolved: Result<void> | undefined

  private constructor(message: string) {
    super(message)
  }

  resolve() {
    return createThunk(async dispatch => {
      if (!this.promise) {
        this.promise = (async () => {
          try {
            await dispatch(this.action)
            this.resolved = resultOk(undefined)
          } catch (e) {
            this.resolved = resultError(asError(e))
            throw e
          }
        })()
      }
      return this.promise
    })
  }

  static create(message: string, key: string, action: ThunkAction<Promise<void>>) {
    const err = new ActionRequiredError(message)
    err.key = key
    err.action = action
    return err
  }
}
