import { createSelector } from 'reselect'

import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { profileUserResultSelector } from '@app/store/selectors/profile'

export const userDataSelector = createSelector([profileUserResultSelector], userResult => {
  return intoResult(() => {
    const user = unwrapResult(userResult)
    if (user?.account_type === 'parent') return { account_type: 'parent', registration_completed: user.registration_completed } as const
    if (user?.account_type === 'sitter')
      return {
        account_type: 'sitter',
        registration_completed: user.registration_completed,
        approved: user.approved,
        training_completed: user.training_completed,
      } as const
    return null
  })
})
