import { ApiNews } from '@app/constants/ApiTypes/entities'

import { getNewsDescriptor, postNewsReadDescriptor } from '@app/store/actions/api/news.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiNews[] | null>(null, builder => {
  builder.addCase(getNewsDescriptor.shapes.fulfilled, (state, action) => {
    if (action.payload.meta.current_page < 2) return action.payload.data
    return [...(state ?? []), ...action.payload.data]
  })

  builder.addCase(postNewsReadDescriptor.shapes.fulfilled, (state, _action) => {
    if (!state) return state
    let changed = false
    const models = state.map(m => {
      if (m.attributes.read) return m
      changed = true
      return { ...m, attributes: { ...m.attributes, read: true } }
    })
    if (!changed) return state
    return models
  })
})
