import { ApiCandidateSurvey } from '@app/constants/ApiTypes/entities'

import { getCandidateSurveyDescriptor } from '@app/store/actions/api/candidate_survey.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ data: ApiCandidateSurvey | null } | null>(null, builder => {
  builder.addCase(getCandidateSurveyDescriptor.shapes.fulfilled, (_state, action) => {
    return { data: action.payload.data }
  })
})
