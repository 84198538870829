import { ApiOnboarding } from '@app/constants/ApiTypes/entities'

import { getSitterOnboardingDescriptor } from '@app/store/actions/api/sitter_onboarding.descriptors'
import { createReducer } from '@app/store/toolkit'

/** available only on server, on client is null */
export default createReducer<ApiOnboarding | null>(null, builder => {
  builder.addCase(getSitterOnboardingDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
