import { ApiSchool } from '@app/constants/ApiTypes/entities'

import { excludeAbortError } from '@app/errors/AbortError'

import { normalize } from '@app/utils/normalizer'

import { getSitterOnboardingDescriptor } from '@app/store/actions/api/sitter_onboarding.descriptors'
import { getSchoolsDescriptor } from '@app/store/actions/initial.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  pending: boolean
  error: any
  models: { [key: string]: ApiSchool }
}>(
  {
    pending: false,
    error: null,
    models: {},
  },
  builder => {
    builder.addCase(getSchoolsDescriptor.shapes.pending, (state, _action) => {
      return { ...state, pending: true, error: null }
    })

    builder.addCase(getSchoolsDescriptor.shapes.fulfilled, (state, action) => {
      const { schools } = normalize(action.payload)
      if (!schools) return state

      return { ...state, pending: false, models: { ...state.models, ...schools } }
    })

    builder.addCase(getSchoolsDescriptor.shapes.rejected, (state, action) => {
      return { ...state, pending: false, error: excludeAbortError(action.payload) }
    })

    builder.addCase(getSitterOnboardingDescriptor.shapes.fulfilled, (state, action) => {
      const { schools } = normalize(action.payload)
      if (!schools) return state

      return { ...state, models: { ...state.models, ...schools } }
    })
  }
)
