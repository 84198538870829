import { ReactNode } from 'react'

import { Cache } from '@app/utils/cache'
import { unimplemented } from '@app/utils/unimplemented'

import './Button.module.scss'
import classes from './ButtonType.module.scss'

export type ButtonType = {
  getClassName: () => string
  getImage?: () => ReactNode
}

class GenericButtonType implements ButtonType {
  private block?: boolean
  private size?: 'large' | 'small'
  private fontSize?: 'regular' | 'small'

  constructor({
    block,
    size,
    fontSize,
  }: {
    block?: boolean
    size?: 'large' | 'small'
    fontSize?: 'regular' | 'small'
  } = {}) {
    this.block = block
    this.size = size
    this.fontSize = fontSize
  }

  protected getBaseClassName(): string {
    // eslint-disable-next-line no-restricted-syntax
    return unimplemented('Unimplemented')
  }

  getClassName() {
    return cn(
      this.getBaseClassName(),
      { [classes.block]: this.block },
      this.size ? classes[`size_${this.size}`] : null,
      this.fontSize ? classes[`font_size_${this.fontSize}`] : null
    )
  }

  private static instanceCache = new Map<typeof GenericButtonType, Cache<GenericButtonType>>()

  /** Returns cached instance (referential equality) */
  static getInstance<T extends typeof GenericButtonType>(
    this: T,
    data: {
      block?: boolean
      size?: 'large' | 'small'
      fontSize?: 'regular' | 'small'
    } = {}
  ) {
    const key = JSON.stringify({ key: this, ...data })
    let cache = this.instanceCache.get(this)
    if (!cache) {
      cache = new Cache()
      this.instanceCache.set(this, cache)
    }
    return cache.upsert(key, () => new this(data)) as InstanceType<T>
  }
}

export class ButtonTypePrimary extends GenericButtonType {
  protected getBaseClassName() {
    return classes.primary
  }
}

export class ButtonTypeDanger extends GenericButtonType {
  protected getBaseClassName() {
    return classes.danger
  }
}

export class ButtonTypeDangerBordered extends GenericButtonType {
  protected getBaseClassName() {
    return classes.danger_bordered
  }
}

export class ButtonTypeWhite extends GenericButtonType {
  protected getBaseClassName() {
    return classes.white
  }
}

export class ButtonTypeTransparent extends GenericButtonType {
  protected getBaseClassName() {
    return classes.transparent
  }
}

export class ButtonTypeBordered extends GenericButtonType {
  protected getBaseClassName() {
    return classes.bordered
  }
}

export class ButtonTypeTertiary extends GenericButtonType {
  protected getBaseClassName() {
    return classes.tertiary
  }
}

export class ButtonTypeTertiaryOK extends GenericButtonType {
  protected getBaseClassName() {
    return classes.tertiary_ok
  }
}

export class ButtonTypeTertiaryDanger extends GenericButtonType {
  protected getBaseClassName() {
    return classes.tertiary_danger
  }
}

export class ButtonTypeInline extends GenericButtonType {
  protected getBaseClassName() {
    return classes.inline
  }
}

export class ButtonTypeText extends GenericButtonType {
  protected getBaseClassName() {
    return classes.text
  }
}
