import { routerRaiseError } from '@app/store/actions/misc.descriptors'
import { navigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  code: number
  message?: string
  localizedMessage?: string
} | null>(null, builder => {
  builder.addCase(navigate.shape, () => {
    return null
  })
  builder.addCase(routerRaiseError.shape, (state, action) => {
    if (state) return state

    return { code: action.payload.errorCode, message: action.payload.message, localizedMessage: action.payload.localizedMessage }
  })
})
