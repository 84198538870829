import { wrapError } from '@app/utils/wrapError'

import { intoResultAsync, unwrapResult } from '@app/packages/Result/Result'

import { createThunk, ThunkAction } from '@app/store/thunk'

import { ActionRequiredError } from './ActionRequiredError'

export const resolveActionRequired = <T>(action: () => T | Promise<T>) => {
  return createThunk(async dispatch => {
    const messages: ActionRequiredError[] = []
    while (true) {
      const res = await intoResultAsync((async () => await action())())
      if (res.error) {
        const errors = getErrors(res.value)
        const actions: ThunkAction<any>[] = []
        for (const err of errors) {
          if (err instanceof ActionRequiredError) {
            if (messages.find(e => e.message === err.message)) {
              throw wrapError(new Error(`Circular resolve issue: ${err.message}`), err)
            }
            messages.push(err)
            actions.push(err.resolve())
          } else {
            throw res.value
          }
        }
        if (actions.length) {
          await Promise.all(actions.map(a => dispatch(a)))
          continue
        }
      }
      return unwrapResult(res)
    }
  })
}

const getErrors = (err: any) => {
  if (err instanceof AggregateError) {
    return err.errors.flatMap(e => getErrors(e))
  }
  return [err]
}
