import { ApiGiftCard } from '@app/constants/ApiTypes/entities'

import { getGiftCardsByIdDescriptor } from '@app/store/actions/giftcard.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<ApiGiftCard | null>(null, builder => {
  builder.addCase(getGiftCardsByIdDescriptor.shapes.fulfilled, (_state, action) => {
    return action.payload.data
  })
})
