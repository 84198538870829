import React, { FunctionComponent, memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '@app/utils/redux'
import { sleep } from '@app/utils/sleep'

import { useForce } from '@app/hooks/useForce'

import { withProgress } from '@app/store/actions/initial'
import { sideMenuOpenedSelector } from '@app/store/selectors/ui'

import { ControlClasses } from '@app/components/ControlClasses/ControlClasses'

import { NavItem, NavMenuItem, NavSubItem } from './navItemsUtils'

export interface SideMenuLayoutProps {
  items: NavMenuItem[]
  currentItems: [NavItem | null, NavSubItem | null]
  children?: React.ReactNode
}

let SideMenu: typeof import('./SideMenu').SideMenu | null = null

export const SideMenuLayout: FunctionComponent<SideMenuLayoutProps> = memo(function SideMenuLayout({ children, items, currentItems }) {
  const dispatch = useAppDispatch()
  const stateopen = useSelector(sideMenuOpenedSelector)
  const [open, setOpen] = useState(false)
  const force = useForce()

  useEffect(() => {
    let aborted = false
    if (stateopen && !SideMenu) {
      dispatch(withProgress(import('./SideMenu'))).then(async m => {
        SideMenu = m.SideMenu
        force()
        if (aborted) return
        await sleep(100)
        if (aborted) return
        setOpen(stateopen)
      })
    } else {
      setOpen(stateopen)
    }

    return () => {
      aborted = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateopen])

  return (
    <div className={cn(ControlClasses.max_height)}>
      <div className={ControlClasses.max_height}>{children}</div>

      {!!IS_BROWSER && SideMenu && <SideMenu currentNavItems={currentItems} navItems={items} open={open} />}
    </div>
  )
})
