import { createSelector } from 'reselect'

import { AddressManager } from '@app/utils/AddressManager'
import { captureException } from '@app/utils/errorReport/errorReport'
import { assertTimezoneValid } from '@app/utils/moment'
import { DEFAULT_CURRENCY } from '@app/utils/printCurrency'
import { RegionsExtensionData } from '@app/utils/regions'
import { matchRegion } from '@app/utils/routing/region'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { createAddress } from '@app/packages/geo/Address'
import { intoResult } from '@app/packages/Result/Result'

import { createSelectorMetaKey } from '@app/store/actions/selector_meta'
import { getFetchProfileTask } from '@app/store/actions/user'
import { StoreState } from '@app/store/store'

import { defaultLabelExtractor } from '@app/components/PlaceInput/shared'

import { countriesSelector } from './countries'
import { currenciesEnabledSelector } from './currencies'
import { localitiesSelector } from './localities'
import { placesSelector } from './places'
import { availableRegionsSlugsSelector, regionsModelsSelector } from './regions'
import { sessionSelector } from './session'

export const profileSelector = (state: StoreState) => state.profile
export const profileMetaSelector = (state: StoreState) => state.profile.meta
export const profileUpdateSelector = (state: StoreState) => state.profile.update
export const profileAvatarIdsSelector = (state: StoreState) => state.profile.avatarIds
export const profileAvatarsUploadSelector = (state: StoreState) => state.profile.avatar_upload
export const profileUserSelector = (state: StoreState) => state.profile.user

export const profileTimezoneOffsetSelector = createSelector([profileUserSelector], profile => {
  if (profile) return profile.utc_offset / 60
  if (IS_BROWSER) {
    return new Date().getTimezoneOffset() * -1
  }

  return 0
})

const profileUserTimezoneStringSelector = createSelector([profileUserSelector], profile => {
  return profile?.timezone
})

export const profilePhoneSelector = createSelector(profileUserSelector, user => {
  let phone: string | null = user && user.phone ? user.phone : null
  if (phone && phone.length > 0 && phone.indexOf('+') !== 0) {
    phone = `+${phone}`
  }

  return phone
})

export const profileUserAccountTypeSelector = createSelector(
  [profileUserSelector],
  user => user && user.account_type && (user.account_type.toLowerCase() as 'parent' | 'visitor' | 'sitter')
)
export const isProfileUserSignedInSelector = createSelector(
  [profileUserSelector],
  user => !!(user && (user.account_type === 'parent' || (user.account_type === 'sitter' && (user.approved || user.training_completed))))
)

export const profileUserIdSelector = (state: StoreState) => state.profile.user?.id ?? null
export const profileUserTokenSelector = (state: StoreState) => (state.profile.user?.account_type === 'sitter' ? state.profile.user.token : null)

const profileUserMetaKey = createSelectorMetaKey<{ session: string }>('profileUser')

export const profileUserResultSelector = createSelector([profileUserMetaKey.selector, sessionSelector, profileUserSelector], (meta, session, user) =>
  intoResult(() => {
    const token = session.access_token
    if (!token) return null
    if (!meta || meta.session !== token)
      throw ActionRequiredError.create('User must be fetched', token, async dispatch => {
        await dispatch(getFetchProfileTask).callLoosely()
        dispatch(profileUserMetaKey.set({ session: token }))
      })
    return user
  })
)

export const registrationCompleteSelector = (state: StoreState) =>
  !!(state.profile.user && state.profile.user.account_type !== 'visitor' && state.profile.user.registration_completed)

export const profileIsParentSelector = createSelector([profileUserAccountTypeSelector], accountType => accountType === 'parent')
export const profileIsSitterSelector = createSelector([profileUserAccountTypeSelector], accountType => accountType === 'sitter')
export const profileIsVisitorSelector = createSelector([profileUserAccountTypeSelector], accountType => accountType === 'visitor')

export const profilePlaceStateSelector = (state: StoreState) => state.profile.place
export const profilePlaceIdSelector = (state: StoreState) => state.profile.place.place_id
export const profilePlaceDataSelector = createSelector(
  [profilePlaceIdSelector, placesSelector, regionsModelsSelector, countriesSelector, localitiesSelector],
  (id, places, regions, countries, localities) => {
    if (!id) throw new Error('Profile place id is empty')
    const place = places[id]
    const region = regions[place.relationships.region.data!.id]
    const country = (place.relationships.country?.data?.id && countries[place.relationships.country.data.id]) || null
    const locality = (place.relationships.locality?.data?.id && localities[place.relationships.locality.data.id]) || null
    return { place, region, country, locality }
  }
)
export const profilePlaceSelector = createSelector([profilePlaceDataSelector], data => data.place)
export const profileRegionSelector = createSelector([profilePlaceDataSelector], data => {
  return {
    ...data.region,
    attributes: { ...data.region.attributes, ...RegionsExtensionData[data.region.attributes.slug as keyof typeof RegionsExtensionData] },
  }
})
export const profilePlaceInputValueSelector = profilePlaceDataSelector

export const profileUserTimezoneSelector = createSelector([profileUserTimezoneStringSelector, profileRegionSelector], (profileTimezone, region) => {
  const tz = (() => {
    if (profileTimezone) return profileTimezone
    if (IS_BROWSER) return Intl.DateTimeFormat().resolvedOptions().timeZone

    return 'Europe/Moscow'
  })()

  try {
    assertTimezoneValid(tz)
  } catch (e) {
    captureException(e)
    return region.attributes.timezone
  }
  return tz
})

export const profilePlaceNameSelector = createSelector([profilePlaceDataSelector], data => defaultLabelExtractor(data))

export const profilePlaceAddressSelector = createSelector([profilePlaceNameSelector, profilePlaceDataSelector], (name, data) =>
  createAddress({
    label: name,
    location: { lat: data.place.attributes.latitude, lon: data.place.attributes.longitude },
  })
)

export const profileRegionIsChangeableSelector = (state: StoreState) =>
  !(
    state.profile.user &&
    (('region_id' in state.profile.user && state.profile.user.region_id) || ('place_id' in state.profile.user && state.profile.user.place_id))
  )

/** landing page's region */
export const profileRegionSlugSelector = createSelector([profileRegionSelector, availableRegionsSlugsSelector], (region, slugs) =>
  matchRegion(slugs, region.attributes.slug)
)
/** landing page's region */
export const profileRegionIdSelector = createSelector(profileRegionSelector, region => region.id)

export const profileSubscriptionPriceSelector = createSelector(
  profileRegionSelector,
  region => region.attributes.subscription_options.find(o => o.period_months === 1)!.price_for_month
)
export const profileCommissionPriceSelector = createSelector(profileRegionSelector, region => region.attributes.commission_fee)

export const profileMapLocationSelector = createSelector([profilePlaceNameSelector, profilePlaceSelector], (name, place) =>
  createAddress({
    label: name,
    location: { lat: place.attributes.latitude, lon: place.attributes.longitude },
  })
)

export const profileCurrencySelector = createSelector([profileUserSelector, currenciesEnabledSelector], (profile, enabled) => {
  if (!enabled) return DEFAULT_CURRENCY
  if (profile?.account_type === 'sitter') return profile.rate_currency
  if (profile?.account_type === 'parent') return profile.display_currency
  return DEFAULT_CURRENCY
})

export const profileSitterAddressSelector = createSelector([profileUserSelector], user => {
  if (user?.account_type !== 'sitter') return undefined
  if (!user.location) return undefined
  return AddressManager.fromLocation(user.location).address
})
