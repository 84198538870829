import { ApiCreditCard, ApiCreditCardPostRequest, ApiStripeSetupIntent } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getCardDescriptor = new ApiActionBuilderDescriptor().setLabel('getCard').setShape<{ data: ApiCreditCard | null }>()
export const deleteCardDescriptor = new ApiActionBuilderDescriptor().setLabel('deleteCard').setShape<unknown>()
export const postCardCloudpaymentsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postCardCloudpayments')
  .setShape<{ data: ApiCreditCardPostRequest | ApiCreditCard }>()
export const postCardCloudpayments3dsDescriptor = new ApiActionBuilderDescriptor().setLabel('postCardCloudpayments3ds').setShape<{ data: ApiCreditCard }>()
export const postCardStripeSetupIntentDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postCardStripeSetupIntent')
  .setShape<{ data: ApiStripeSetupIntent }>()
