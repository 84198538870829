import { Result, unwrapResult } from '@app/packages/Result/Result'

import { StoreState } from '@app/store/store'

export const useSelectorResult: typeof import('./useSelectorResult.client').useSelectorResult = IS_BROWSER
  ? require('./useSelectorResult.client').useSelectorResult
  : require('./useSelectorResult.server').useSelectorResult

export const useSelectorResultValue = <T>(selector: (state: StoreState) => Result<T>) => {
  const val = useSelectorResult(selector)
  return unwrapResult(val)
}

export const clearErrors: typeof import('./useSelectorResult.client').clearErrors = IS_BROWSER
  ? require('./useSelectorResult.client').clearErrors
  : require('./useSelectorResult.server').clearErrors
