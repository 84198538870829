import React, { Component, FunctionComponent, PropsWithChildren } from 'react'

export type FallbackRenderProps<P> = P extends undefined
  ? { error: Error; componentStack: string; reset: () => void; ctx?: P }
  : { error: Error; componentStack: string; reset: () => void; ctx: P }
export type FallbackRender<P = unknown> = FunctionComponent<FallbackRenderProps<P>>

type State = { errorState: { error: Error; componentStack: string } | null }

export class ErrorBoundary<P> extends Component<PropsWithChildren<{ fallback: FallbackRender<P>; ctx?: P }>, State> {
  state: State = {
    errorState: null,
  }

  componentDidCatch(error, { componentStack }: { componentStack: string }) {
    this.setState({ errorState: { error, componentStack } })
  }

  render() {
    if (this.state.errorState) {
      const Fallback = this.props.fallback as any
      const props = {
        componentStack: this.state.errorState.componentStack,
        ctx: this.props.ctx,
        error: this.state.errorState.error,
        reset: this.resetError,
      } as FallbackRenderProps<P>
      return <Fallback {...props} />
    }

    return this.props.children
  }

  resetError = () => {
    this.setState({ errorState: null })
  }
}
