import classes from './ControlClasses.module.scss'

const hide = ({
  mobile = false,
  tablet = false,
  desktop = false,
}: {
  mobile?: boolean
  tablet?: boolean
  desktop?: boolean
} = {}) =>
  cn({
    [classes.hide_mobile]: mobile,
    [classes.hide_tablet]: tablet,
    [classes.hide_desktop]: desktop,
  })

export const ControlClasses = {
  link: 'link',
  link_inline: 'link-inline',
  m_no_appearance: 'm-no-appearance',
  hide,
  hide_mobile: hide({ mobile: true, tablet: true }),
  only_mobile: hide({ desktop: true }),
  /** sets height: 100% */
  max_height: classes.max_height,
  /** sets height: 100% only for mobile */
  max_height_mobile: classes.max_height_mobile,
  pseudo: classes.pseudo,
  button: classes.button,
  pseudo_colored: classes.pseudo_colored,
  visually_hidden: classes.visually_hidden,
  full_width: classes.full_width,
}
