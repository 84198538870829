import { useDeferredValue } from 'react'
import { useSelector } from 'react-redux'

import { isAbortError } from '@app/errors/AbortError'

import { useAppDispatch } from '@app/utils/redux'
import { sleep } from '@app/utils/sleep'
import { wrapError } from '@app/utils/wrapError'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { Result } from '@app/packages/Result/Result'
import { StackContextValue, useStackContext } from '@app/packages/StackContext/StackContext'

import { StoreState } from '@app/store/store'

export const useSelectorResult = <T>(selector: (state: StoreState) => Result<T>) => {
  const dispatch = useAppDispatch()
  const svalue = useSelector(selector)
  const value = useDeferredValue(svalue)
  const stackContext = useStackContext()

  if (value.error) {
    const errors = getErrors(value.value)

    const promises: Promise<any>[] = []
    for (const error of errors) {
      if (error instanceof ActionRequiredError) {
        const err = getMemoizedError(stackContext, error)
        if (err.resolved) {
          setTimeout(() => {
            clearError(stackContext, err)
          }, 10)
          if (err.resolved.error && isAbortError(err.resolved.value)) {
            throw sleep(10)
          } else if (err.resolved.error) {
            throw err.resolved.value
          } else {
            throw wrapError(new Error(`Circular resolve issue: ${err.message}`), err)
          }
        }
        promises.push(stackContext.handleAction(dispatch(withAbortSignal(stackContext.abortController.signal, err.resolve()))))
      }
    }
    if (promises.length) throw Promise.all(promises)

    return value
  }

  return value
}

const getErrors = (err: any) => {
  if (err instanceof AggregateError) {
    return err.errors.flatMap(e => getErrors(e))
  }
  return [err]
}

const getMemoizedError = (ctx: StackContextValue, error: ActionRequiredError) => {
  if (!ctx.store[ActionRequiredErrorStore]) {
    ctx.store[ActionRequiredErrorStore] = {}
  }

  const err = ctx.store[ActionRequiredErrorStore][error.message] as ActionRequiredError
  if (err && err.key === error.key) return err

  ctx.store[ActionRequiredErrorStore][error.message] = error
  return error
}

const clearError = (ctx: StackContextValue, error: ActionRequiredError) => {
  if (!ctx.store[ActionRequiredErrorStore]) {
    ctx.store[ActionRequiredErrorStore] = {}
  }

  delete ctx.store[ActionRequiredErrorStore][error.message]
}

export const clearErrors = (ctx: StackContextValue) => {
  if (!ctx.store[ActionRequiredErrorStore]) ctx.store[ActionRequiredErrorStore] = {}
  ctx.store[ActionRequiredErrorStore] = {}
}

const ActionRequiredErrorStore = Symbol('ActionRequiredErrorStore')
