import React, { FunctionComponent, useDeferredValue, useEffect, useLayoutEffect, useMemo, useState, useTransition } from 'react'
import { NavigationType, Router as ReactRouter } from 'react-router'

import { Channel } from '@app/utils/channel'
import { useAppDispatch } from '@app/utils/redux'

import { TrackableValue } from '@app/packages/reactives/TrackableValue'

import { useOnChange } from '@app/hooks/useOnChange'

import { getProgress } from '@app/store/actions/initial'

import { RouterContext, RouterContextInterface, setRouter } from './Context'
import { matchRoutes } from './functions'
import { RouteRenderer } from './RouteRenderer'
import { Action, History, Location, LocationDescriptorObject, MatchedRoute, Route, RouterState } from './types'
import { getRootMatch } from './utils'

type RouterProps = {
  afterRender?: (location: LocationDescriptorObject, matchedRoutes: MatchedRoute[]) => Promise<unknown>
}

type RouterInnerState = {
  routes: Route[]
  matchedRoutes: MatchedRoute[]
  location: Location
  action: Action
  routerState: RouterState
}

export const RouterFactory = (regions: string[], history: History, stateResult: TrackableValue<RouterTrackableState>) => {
  /**
   * The main difference with stock router
   * (https://github.com/ReactTraining/react-router/blob/520a0acd48ae1b066eb0b07d6d4d1790a1d02482/packages/react-router/modules/Router.js#L10)
   * is that it doesn't listen to history changes.
   *
   * In our case we fire rerender manually by `render`
   * function in `@app/render.js:render` which is fired
   * after history update via chain `@app/app.client.js:app -> @app/app.client.js:run`
   *
   * TODO: RouterContext is considered unstable and can be changed any time
   * by maintainers of `react-router`
   */
  const Router: FunctionComponent<RouterProps> = ({ afterRender }) => {
    const dispatch = useAppDispatch()
    const [isPending, startTransition] = useTransition()
    const [stateTrackableResult, setStateTrackableResult] = useState(stateResult.value)

    if (stateTrackableResult.type === 'pending') {
      throw stateTrackableResult.promise.then(() => {
        startTransition(() => {
          setStateTrackableResult(stateResult.value)
        })
      })
    }

    if (stateTrackableResult.type === 'error') {
      throw stateTrackableResult.error
    }

    const state = stateTrackableResult.value

    const [routingState, setRoutingState] = useState((): RouterInnerState => {
      const location = state.location
      const matchedRoutes = matchRoutes(regions, state.routes, history.location.pathname)
      const match = matchedRoutes.at(-1)
      const action = NavigationType.Push

      const routerState = { ...match!, location, action }

      return { routes: state.routes, matchedRoutes, location, action, routerState }
    })
    const deferredState = useDeferredValue(routingState)

    const matchedRoute = deferredState.matchedRoutes.at(-1) ?? null

    const routerContext = useMemo<RouterContextInterface>(
      () => ({
        history,
        location: deferredState.location,
        match: matchedRoute ? matchedRoute.match : getRootMatch(),
        route: matchedRoute?.route ?? null,
      }),
      [deferredState.location, matchedRoute]
    )

    useLayoutEffect(() => {
      const unlisten = stateResult.listen(val => {
        startTransition(() => {
          setStateTrackableResult(val)
        })
      })

      return () => {
        unlisten()
      }
    }, [])

    useEffect(() => {
      if (isPending) {
        const progress = dispatch(getProgress())
        const channel = new Channel<void>()
        progress.wrap(channel.wait(), false)

        return () => {
          channel.resolve()
        }
      }
    }, [dispatch, isPending])

    useLayoutEffect(() => {
      startTransition(() => {
        setRoutingState(state)
      })
    }, [state])

    useOnChange(() => {
      afterRender?.(deferredState.location, deferredState.matchedRoutes)
    }, [deferredState.location])

    useLayoutEffect(() => {
      startTransition(() => {
        dispatch(setRouter(routerContext))
      })
    }, [dispatch, routerContext])

    return (
      <RouterContext.Provider value={routerContext}>
        <ReactRouter location={deferredState.location} navigationType={deferredState.action} navigator={history}>
          <RouteRenderer routes={deferredState.routes} />
        </ReactRouter>
      </RouterContext.Provider>
    )
  }

  return Router
}

export type RouterTrackableState =
  | {
      type: 'pending'
      promise: Promise<any>
    }
  | {
      type: 'success'
      value: RouterInnerState
    }
  | {
      type: 'error'
      error: Error
    }
