import { createSelector } from 'reselect'

import { defaultAnnouncementMeta } from '@app/store/misc/announcements'
import { StoreState } from '@app/store/store'
import { StoreUserParent, StoreUserSitter } from '@app/store/types/users'

import { announcementsModelsMetaSelector, announcementsModelsSelector } from './announcement'
// fixing circular dependency
import { announcementResponsesSelector as __announcementResponsesSelector } from './announcementResponse.internal'
import { locationsSelector } from './misc'
import { usersSelector } from './user'

export const announcementResponsesSelector = __announcementResponsesSelector

export const announcementResponseByIdSelectorActor = (
  id: string | null,
  responses: ReturnType<typeof announcementResponsesSelector>,
  users: ReturnType<typeof usersSelector>,
  announcements: ReturnType<typeof announcementsModelsSelector>,
  announcements_meta: ReturnType<typeof announcementsModelsMetaSelector>,
  locations: ReturnType<typeof locationsSelector>
) => {
  if (!id) return null

  const response = responses[id]
  if (!response) return null

  if (!response.relationships.sitter || !response.relationships.sitter.data) return null
  const sitter = users[response.relationships.sitter.data.id] as StoreUserSitter
  if (!sitter) return null

  if (!response.relationships.announcement || !response.relationships.announcement.data) return null
  const announcement = announcements[response.relationships.announcement.data.id]
  if (!announcement) return null

  const announcement_meta = announcements_meta[announcement.id] || defaultAnnouncementMeta

  if (!announcement.relationships.location || !announcement.relationships.location.data) return null
  const location = locations[announcement.relationships.location.data.id]
  if (!location) return null

  if (!announcement.relationships.parent || !announcement.relationships.parent.data) return null
  const parent = users[announcement.relationships.parent.data.id] as StoreUserParent
  if (!parent) return null

  return {
    response,
    announcement: {
      announcement,
      meta: announcement_meta,
      location,
      parent,
      sitter,
      responses: [
        {
          response,
          parent,
          sitter,
          location,
          announcement,
        },
      ],
    },
    location,
    parent,
    sitter,
  }
}

export const makeAnnouncementResponseByIdSelector = (announcementResponseIdSelector: (state: StoreState, props?: any) => string | null) =>
  createSelector(
    [
      announcementResponseIdSelector,
      announcementResponsesSelector,
      usersSelector,
      announcementsModelsSelector,
      announcementsModelsMetaSelector,
      locationsSelector,
    ],
    announcementResponseByIdSelectorActor
  )
