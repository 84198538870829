import { ApiCreditCard } from '@app/constants/ApiTypes/entities'

import {
  deleteCardDescriptor,
  getCardDescriptor,
  postCardCloudpayments3dsDescriptor,
  postCardCloudpaymentsDescriptor,
} from '@app/store/actions/api/card.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ data: ApiCreditCard | null } | null>(null, builder => {
  builder.addCase(postCardCloudpaymentsDescriptor.shapes.fulfilled, (state, action) => {
    if (action.payload.data.type === 'post3ds') return state

    return { data: action.payload.data }
  })
  builder.addCases([postCardCloudpayments3dsDescriptor.shapes.fulfilled, getCardDescriptor.shapes.fulfilled], (_state, action) => {
    return { data: action.payload.data }
  })

  builder.addCase(deleteCardDescriptor.shapes.fulfilled, () => ({ data: null }))
})
