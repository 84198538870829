import React, { FunctionComponent, PropsWithChildren } from 'react'

import classes from './Modal.module.scss'

export const ModalSeparator: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  return <div className={classes.separator}>{children}</div>
}

export const ModalContent: FunctionComponent<PropsWithChildren<{ expand?: boolean; flow?: 'column_center' | 'column_stretch'; className?: string }>> = ({
  expand,
  flow,
  className,
  children,
}) => {
  return (
    <div className={cn(classes.modal_content, { [classes.modal_content_expand]: expand }, flow ? classes[`modal_content_flow_${flow}`] : null, className)}>
      {children}
    </div>
  )
}
