import { createSelector } from 'reselect'

import moment from '@app/utils/moment'
import { RouterState } from '@app/utils/routing/types'

import { StoreState } from '@app/store/store'

export const routingSelector = (state: StoreState) => state.routing
export const routingStateSelector = <T extends {} = {}>(state: StoreState) => state.routing.state as RouterState<T> | null
export const routingLocationSelector = (state: StoreState) => state.routing.state?.location
export const routingIsTransitioningSelector = (state: StoreState) => state.routing.isTransitioning
export const routingUUIDSelector = (state: StoreState) => state.routing.uuid
export const routingDateSelector = createSelector([(state: StoreState) => state.routing.date], date => moment(date))
