import React, { FunctionComponent, PropsWithChildren, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { captureException } from '@app/utils/errorReport/errorReport'

import { clearErrors } from '@app/packages/selectorResult/useSelectorResult'
import { useStackContext } from '@app/packages/StackContext/StackContext'

import { useOnChange } from '@app/hooks/useOnChange'

import { routingUUIDSelector } from '@app/store/selectors/routing'

import { ErrorBoundary, FallbackRender } from '@app/components/ErrorBoundary'

export const AppErrorBoundary: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <ErrorBoundary fallback={Fallback}>{children}</ErrorBoundary>
}

const Fallback: FallbackRender = ({ error, componentStack, reset }) => {
  const stackContext = useStackContext()
  const uuid = useSelector(routingUUIDSelector)

  const errorID = useMemo(() => v4(), [])

  useOnChange(() => {
    clearErrors(stackContext)
    reset()
  }, [uuid])

  useEffect(() => {
    captureException(error, { silent: true, tags: { error_uuid: errorID } })
  }, [error, errorID])

  return <ErrorPage error={error} errorID={errorID} info={componentStack} />
}

const ErrorPage = React.lazy(() => import('@app/components/ErrorPage/ErrorPage').then(m => ({ default: m.ErrorPage })))
