import { ApiGiftCardType } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getGiftCardsByIdDescriptor, getGiftCardsTypesDescriptor } from '@app/store/actions/giftcard.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiGiftCardType }>({}, builder => {
  builder.addCase(getGiftCardsTypesDescriptor.shapes.fulfilled, (state, action) => {
    const { gift_card_types } = normalize(action.payload)
    if (!gift_card_types) return state
    return gift_card_types
  })
  builder.addCase(getGiftCardsByIdDescriptor.shapes.fulfilled, (state, action) => {
    const { gift_card_types } = normalize(action.payload)
    if (!gift_card_types) return state

    return { ...state, ...gift_card_types }
  })
})
