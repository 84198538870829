import { IMPORT_MAP } from '@app/importMap'

import { waitFor } from '@app/utils/waitFor'

import { createThunk } from '@app/store/thunk'

export function requireTOSAccept() {
  return createThunk<Promise<boolean>>(async (_dispatch, getState, { mount }) => {
    const {
      session,
      profile: { user },
      config: { isApp },
      routing,
    } = getState()
    if (
      !user ||
      user.tos_accepted ||
      isApp ||
      session.supervisor ||
      routing.state?.location.pathname === '/licence' ||
      routing.state?.location.pathname === '/licence-parent' ||
      routing.state?.location.pathname === '/licence-sitter'
    )
      return true

    const mountCtx = await waitFor(() => mount)

    return new Promise<boolean>(resolve => {
      const close = mountCtx.push(
        IMPORT_MAP.modals.TOSModal().then(m => m.TOSModal),
        {
          autoclose: false,
          zIndex: 100,
          props: {
            onSuccess: async () => {
              await close()
              resolve(true)
            },
          },
        }
      )
    })
  })
}
