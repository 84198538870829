import * as api from '@app/utils/api'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { Task } from '@app/packages/task/Task'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { ApiError, asNetworkError } from '@app/store/apiMiddleware/errors'
import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { createThunk } from '@app/store/thunk'
import { StoreUser } from '@app/store/types/users'

import { getUsersById, getUsersByToken, getUsersMe } from './api/users'
import { getState } from './initial'
import { setSession } from './session'
import { createTask } from './tasks'
import { resendEmailConfirmationDescriptor, sendEmailConfirmationDescriptor } from './user.descriptors'

export const getFetchProfileTask = createTask('C2892857-F197-4141-9C13-8B1F2F37043A:fetchProfile', dispatch =>
  Task.create(ctx => async () => {
    const sessionToken = dispatch(getState()).session.access_token
    if (!sessionToken) return
    const resp = await dispatch(withAbortSignal(ctx.abortController.signal, getUsersMe()))
    if (resp?.error && asNetworkError(resp.payload)?.status === 401) return undefined

    const sessionUpdatePromise = (async () => {
      const state = dispatch(getState())

      await dispatch(setSession({ access_token: state.session.access_token || '' }))
    })()

    await sessionUpdatePromise

    return resp
  })
)

const fetchUserPromisesKey = Symbol('fetchUserPromises')

export function getFetchUserPromises() {
  return createThunk((_dispatch, _getState, context) => {
    if (!context[fetchUserPromisesKey]) {
      context[fetchUserPromisesKey] = new Map<string, ApiActionPromise<StoreUser>>()
    }
    return context[fetchUserPromisesKey] as Map<string, ApiActionPromise<StoreUser>>
  })
}

export function fetchUser(
  payload: { type: 'id'; value: string } | { type: 'token'; value: string },
  { force = false, ensureAccountType }: { force?: boolean; ensureAccountType?: 'sitter' | 'parent' } = {}
) {
  return createThunk<ApiActionPromise<StoreUser>>(async (dispatch, getState) => {
    const fetchUserPromises = dispatch(getFetchUserPromises())

    if (payload.value.includes('support'))
      return {
        error: true,
        payload: new ApiError(404, `Attempt to fetch user with id: ${payload.value}`, undefined),
      }

    const state = getState()
    const user = state.users.models[payload.value]

    if (!force && user && user.avatarsComplete) {
      if (ensureAccountType && user.account_type !== ensureAccountType) return { error: true, payload: new Error('Invalid account type') }
      return { error: false, payload: user }
    }

    const promiseId = `${payload.type}:${payload.value}`

    const promise = (() => {
      const cached = fetchUserPromises.get(promiseId)
      if (cached) return cached

      const promise = dispatch(payload.type === 'id' ? getUsersById(payload.value) : getUsersByToken(payload.value)).then(p => {
        fetchUserPromises.delete(promiseId)
        if (p?.error) return p

        const user = getState().users.models[payload.value]
        if (!user) return { error: true as const, payload: new Error('User not found') }
        if (ensureAccountType && user.account_type !== ensureAccountType) {
          return { error: true as const, payload: new Error('Invalid account type') }
        }

        return { error: false as const, payload: user }
      })

      fetchUserPromises.set(promiseId, promise)

      return promise
    })()

    return promise
  })
}

export const sendEmailConfirmation = new ApiActionBuilder(sendEmailConfirmationDescriptor)
  .setInit((token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/email_confirmation'),
    headers: api.headers(),
    body: JSON.stringify({ token }),
    bailout: ({ emailConfirmation }) => !!(emailConfirmation.data.email || emailConfirmation.data.error),
  }))
  .build()

export const resendEmailConfirmation = new ApiActionBuilder(resendEmailConfirmationDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/email_confirmation/resend'),
    headers: api.headers(),
  }))
  .build()
