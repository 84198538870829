import moment from '@app/utils/moment'

import { setKeyValue } from '@app/store/actions/key_values'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: { fetchedAt: string; value: null | string } }>({}, builder => {
  builder.addCase(setKeyValue.shape, (state, action) => {
    return { ...state, [action.payload.key]: { fetchedAt: moment().format(), value: action.payload.value } }
  })
})
