import React, { FunctionComponent, ReactNode } from 'react'

import { Icon18 } from '@app/components/Icon/Icon'

export const intlFormatters = {
  whitespace: <span className="whitespace" />,
  zspace: <span className="zspace" />,
  dashspace: <span className="dashspace" />,
}

export const enum NavItemVisibility {
  Header = 0b100,
  Footer = 0b010,
  Side = 0b001,

  None = 0b000,
  All = 0b111,
}

export type NavItemTitleComponent = FunctionComponent<{ type: 'header' | 'side' | 'footer' }>

export interface NavSubItem {
  type: 'item'
  id: string
  url?: string
  /** default: true. If url present, but wrapInLink is false then title will not be wrapped in Link Component  */
  wrapInLink?: boolean
  /** whether region prefix should be appended to url */
  region?: boolean
  matches?: (pathname: string) => boolean
  onClick?: (e: { preventDefault: () => unknown }) => unknown
  disabled?: boolean
  app?: boolean
  title: ReactNode | NavItemTitleComponent
  count?: number
}

export interface NavSignOut {
  type: 'signout'
  id: string
}

export interface NavDesupervise {
  type: 'user-desupervise'
  id: string
  name: string
}

export interface NavDestroy {
  type: 'user-destroy'
  id: string
  name: string
}

export interface NavExpander {
  type: 'expander'
  id: string
}

export interface NavSpacer {
  type: 'spacer'
  id: string
  width: 'small' | 'normal' | 'wide'
}

export type NavMenuChild = NavSubItem | NavSignOut | NavDesupervise | NavDestroy | NavExpander | NavSpacer

export interface NavItem {
  type: 'item'
  id: string
  url: string
  external?: boolean
  /** whether region prefix should be appended to url */
  region?: boolean
  matches?: (pathname: string) => boolean
  icon?: Icon18
  title: ReactNode | NavItemTitleComponent
  subtype?: 'settings'
  count?: number
  visibility?: NavItemVisibility
  children?: NavMenuChild[]
}

export type NavMenuItem = NavItem | NavExpander | NavSpacer

export const matchCurrentItem = (pathname: string, items: NavMenuItem[]): [NavItem | null, NavSubItem | null] => {
  for (const item of items) {
    if (item.type !== 'item') continue
    if (item.children) {
      for (const subitem of item.children) {
        if (subitem.type !== 'item') continue
        if (subitem.matches && subitem.matches(pathname)) return [item, subitem]
        if (subitem.url === pathname) return [item, subitem]
      }
    }
    if (item.matches ? item.matches(pathname) : item.url === pathname) return [item, null]
  }

  return [null, null]
}
