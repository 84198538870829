import { ensureType } from '@app/utils/ensureType'

export interface Config {
  appRequestId: string
  port: number | string
  mode: string
  isStaging: boolean
  apiUrl: string
  webUrl: string
  fbClient: string
  fbScope: string
  vkClient: string
  vkScope: string
  cloudpayments: string
  stripe: string
  proxy: boolean
  accountManagement: boolean
  yandexVerification: string | null
  googleSiteVerification: string | null
  ymTrackingCode: string | null
  gaTrackingCode: string | null
  tagManager: string | null
  firebase: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
  }
  sentry: boolean
  amplitude: string | null
  supportAvatarUrl: string
  ymapsKey: string | null
  ymapsGeosuggestKey: string | null
  googleMapsKey: string | null
  amoWidgetId: string | null
  amoChatId: string | null
  facebookPixelId: string | null
  telegramBotToken: string | null
  paypalClient: string
  /**
   * Due to restriction of Russian government, usage of products belonged to Meta Corporation
   * is forbidden and punishable by law. Flag can be toggled when ban will be lifted.
   */
  metaCorpEnabled: boolean
}

const config: Config = IS_BROWSER
  ? (window as any).CONFIG
  : ensureType<Config>({
      appRequestId: '',
      port: process.env.SERVER_PORT ?? 4000,
      mode: process.env.MODE ?? 'production',
      isStaging: process.env.MODE === 'staging',
      apiUrl: process.env.API_URL!,
      webUrl: process.env.WEB_URL!,
      fbClient: process.env.FB_CLIENT!,
      fbScope: process.env.FB_SCOPE!,
      vkClient: process.env.VK_CLIENT!,
      vkScope: process.env.VK_SCOPE!,
      cloudpayments: process.env.CLOUDPAYMENTS!,
      stripe: process.env.STRIPE!,
      proxy: JSON.parse(process.env.PROXY ?? String(process.env.NODE_ENV !== 'production')),
      accountManagement: JSON.parse(process.env.ACCOUNT_MANAGEMENT ?? String(process.env.NODE_ENV !== 'production')),
      yandexVerification: process.env.YANDEX_VERIFICATION || null,
      googleSiteVerification: process.env.GOOGLE_SITE_VERIFICATION || null,
      ymTrackingCode: process.env.YM_TRACKING_CODE || null,
      gaTrackingCode: process.env.GA_TRACKING_CODE || null,
      tagManager: process.env.TAG_MANAGER || null,
      firebase: {
        apiKey: process.env.FIREBASE_API_KEY!,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN!,
        databaseURL: process.env.FIREBASE_DATABASE_URL!,
        projectId: process.env.FIREBASE_PROJECT_ID!,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET!,
        messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID!,
        appId: process.env.FIREBASE_APP_ID!,
      },
      sentry: !!process.env.SENTRY_ORG,
      amplitude: process.env.AMPLITUDE || null,
      supportAvatarUrl: '/support-avatar-288.png',
      ymapsKey: process.env.YANDEX_MAPS_KEY!,
      ymapsGeosuggestKey: process.env.YANDEX_GEOSUGGEST_KEY!,
      googleMapsKey: process.env.GOOGLE_MAPS_KEY || null,
      amoWidgetId: process.env.AMO_WIDGET_ID || null,
      amoChatId: process.env.AMO_CHAT_ID || null,
      facebookPixelId: process.env.FACEBOOK_PIXEL_ID || null,
      telegramBotToken: process.env.TELEGRAM_BOT_TOKEN ?? null,
      paypalClient: process.env.PAYPAL_API_USER!,
      metaCorpEnabled: process.env.METACORP_ENABLED === '1',
    })

export default config
