import { ApiSocialIdentity } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getIdentitiesDescriptor = new ApiActionBuilderDescriptor().setLabel('getIdentities').setShape<{ data: ApiSocialIdentity[] }>()

export const postIdentitiesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postIdentities')
  .setShape<{ data: ApiSocialIdentity }, { provider: ApiSocialIdentity['attributes']['provider']; token: string }>()

export const deleteIdentitiesByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('deleteIdentitiesById').setShape<unknown, { id: string }>()
