import { useCallback, useEffect, useState } from 'react'

export const useToggle = (value: boolean = false) => {
  const [v, s] = useState(value)
  const toggle = useCallback(() => s(v => !v), [])
  return [v, toggle] as const
}

export const useToggleArray = <T>(values: readonly T[], initial?: (arr: readonly T[]) => T, deps: any[] = []) => {
  const [v, s] = useState(() => (initial ? initial(values) : values[0]))
  const toggle = useCallback(
    () =>
      s(v => {
        const index = values.indexOf(v)
        return values[Math.max(0, (index + 1) % values.length)]
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values
  )

  useEffect(() => {
    if (!values.includes(v)) s(initial ? initial(values) : values[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps])

  return [v, toggle, s] as const
}
