import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'

import { useAppDispatch } from '@app/utils/redux'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { StackContextProvider, StackContextValue } from '@app/packages/StackContext/StackContext'

import { withProgress } from '@app/store/actions/initial'

export const AppStackContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch()
  const abortController = useMemo(() => new AbortController(), [])

  const value = useMemo<StackContextValue>(
    () => ({
      stackId: 'main',
      store: {},
      abortController,
      handleAction: promise => {
        return dispatch(withAbortSignal(abortController.signal, withProgress(promise, false)))
      },
    }),
    [abortController, dispatch]
  )

  return <StackContextProvider value={value}>{children}</StackContextProvider>
}
