import { Context, createContext } from 'react'

import { createThunk } from '@app/store/thunk'

import { RouteComponentProps } from './types'

export type RouterContextInterface = RouteComponentProps<{}>

export const RouterContext: Context<RouterContextInterface> = createContext<RouterContextInterface>(null as any)

export function setRouter(value: RouterContextInterface) {
  return createThunk<void>((_dispatch, _getState, context) => {
    context.router = value
    return
  })
}
