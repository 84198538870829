import * as valita from '@badrap/valita'

import { JSONSerializable } from '@app/utils/jsonSerializable'
import moment from '@app/utils/moment'

import { createKeyValue } from './key_values'

export class SeenAtState implements JSONSerializable {
  constructor(public seenAt?: moment.Moment) {}

  get seen() {
    return !!this.seenAt
  }

  toJSON() {
    return { seen_at: this.seenAt?.format() ?? null }
  }

  static fromJSON(data: unknown) {
    const RT_DATE = valita
      .string()
      .assert(v => moment(v)?.isValid(), 'Invalid date')
      .map(str => moment(str))

    const result = valita
      .object({ seen_at: RT_DATE })
      .map(data => new SeenAtState(data.seen_at))
      .try(data)
    if (!result.ok) return new SeenAtState()
    return result.value
  }
}

export const onboardingApplicationStepsSeenStateKeyValue = createKeyValue(
  '7FD35633-37AD-49A9-8188-4D138533DB81:onboarding_application_steps_seen_state',
  SeenAtState
)

export const onboardingTestPassedSeenStateKeyValue = createKeyValue('C4275522-8C33-44CF-AE9A-71E3681BB76A:onboarding_test_passed_seen_state', SeenAtState)
export const onboardingTrainingPassedSeenKeyValue = createKeyValue('1E121C8B-5E01-4472-8A30-08B4AB7237E2:onboarding_training_passed_seen_state', SeenAtState)
export const approvedBannerSeenStateKeyValue = createKeyValue('D4075139-0819-40DF-B1D6-9033F5B943EE:approved_banner_seen_state', SeenAtState)
