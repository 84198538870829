import { ApiOnboardingStep } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getSitterOnboardingDescriptor } from '@app/store/actions/api/sitter_onboarding.descriptors'
import { createReducer } from '@app/store/toolkit'

/** available only on server, on client is null */
export default createReducer<{ [key: string]: ApiOnboardingStep }>({}, builder => {
  builder.addCase(getSitterOnboardingDescriptor.shapes.fulfilled, (state, action) => {
    const { onboarding_steps } = normalize(action.payload)
    if (!onboarding_steps) return state
    return { ...state, ...onboarding_steps }
  })
})
