import { createSelector } from 'reselect'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getFeaturesTask } from '@app/store/actions/initial'
import { createSelectorMetaKey } from '@app/store/actions/selector_meta'
import { StoreState } from '@app/store/store'

import { profileUserResultSelector } from './profile'

const featuresMetaKey = createSelectorMetaKey<{ user_id: string }>('features')

export const featuresResultSelector = createSelector(
  [featuresMetaKey.selector, profileUserResultSelector, (state: StoreState) => state.features],
  (meta, userResult, features) =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (!user) return []
      if (!meta || meta.user_id !== user.id)
        throw ActionRequiredError.create('Features must be fetched', user.id, async dispatch => {
          await dispatch(getFeaturesTask).callLoosely()
          dispatch(featuresMetaKey.set({ user_id: user.id }))
        })
      if (!features) throw new Error('Features missing')
      return features
    })
)

export const createFeatureEnabledSelector = (name: string) =>
  createSelector([featuresResultSelector], featuresResult =>
    intoResult(() => {
      const features = unwrapResult(featuresResult)
      return !!features.find(f => f.attributes.name === name && f.attributes.enabled)
    })
  )

export const stripeEnabledSelector = createFeatureEnabledSelector('stripe')
export const savedAnnouncementsSearchEnabledSelector = createFeatureEnabledSelector('saved_announcements_searches')
export const onboardingV2EnabledResultSelector = createFeatureEnabledSelector('candidate_onboarding_v2')
