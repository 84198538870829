import React, { FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'

import { useRouter } from './hooks'
import { StaticRedirect } from './StaticRedirect'
import { LocationDescriptor, LocationDescriptorObject } from './types'

export type RedirectLocation = LocationDescriptor | ((location: LocationDescriptorObject) => LocationDescriptor)

interface RedirectDescriptor {
  to: RedirectLocation
  permanent: boolean
}

export const Redirect: FunctionComponent<{
  to: RedirectLocation
  permanent?: boolean
}> = props => {
  const { location, history } = useRouter()
  const to = typeof props.to === 'function' ? props.to(location) : props.to
  if (history.staticContext) {
    history.staticContext.redirect = new StaticRedirect(to, props.permanent ? 301 : 302)
    return null
  }
  return <Navigate to={to} />
}

export const createRedirect = (to: RedirectLocation, permanent?: boolean) => {
  const cmp: FunctionComponent = () => <Redirect permanent={!!permanent} to={to} />
  const descriptor: RedirectDescriptor = { to, permanent: !!permanent }
  cmp[REDIRECT_ID_KEY] = descriptor
  return cmp
}

export function getRedirect(cmp: object): RedirectDescriptor | undefined {
  return cmp[REDIRECT_ID_KEY]
}

const REDIRECT_ID_KEY = '__KIDSOUT_REDIRECT__/ID'
