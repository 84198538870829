import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'

import { LocalizedRequestError } from '@app/errors/LocalizedRequestError'

import { RouteRenderer } from '@app/utils/routing/RouteRenderer'
import { RouteConfigComponentProps } from '@app/utils/routing/types'

import { StoreState } from '@app/store/store'

import MountContainer from './Mount/Mount'

const Root: FunctionComponent<RouteConfigComponentProps> = ({ route }) => {
  const error = useSelector(errorSelector)

  if (error) throw LocalizedRequestError.create(error.message ?? '', error.code, error.localizedMessage ?? '')
  if (!route) throw new RouteNotFoundError()

  return (
    <MountContainer>
      <RouteRenderer routes={route.routes} />
    </MountContainer>
  )
}

export default Root

const errorSelector = (state: StoreState) => state.error

class RouteNotFoundError extends Error {
  status = 404

  constructor() {
    super('Route not found')
  }
}
