import { ApiAppStoreCustomerReview } from '@app/constants/ApiTypes/entities'

import { normalize } from '@app/utils/normalizer'

import { getAppStoreCustomerReviewsByQueryDescriptor } from '@app/store/actions/appStoreCustomerReviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<Record<string, ApiAppStoreCustomerReview> | null>(null, builder => {
  builder.addCase(getAppStoreCustomerReviewsByQueryDescriptor.shapes.fulfilled, (state, action) => {
    const reviews = normalize(action.payload).app_store_customer_reviews ?? {}
    if (action.payload.meta.current_page < 2) return reviews
    return { ...state, ...reviews }
  })
})
