import { createSelector } from 'reselect'

import { defaultAnnouncementMeta } from '@app/store/misc/announcements'
import { StoreState } from '@app/store/store'

import { AnnouncementCardBundle } from '@app/components/AnnouncementCard/types'

import { announcementResponsesSelector } from './announcementResponse.internal'
import { locationsSelector } from './misc'
import { profilePlaceAddressSelector, profileSitterAddressSelector } from './profile'
import { usersSelector } from './user'

export const announcementsMetaSelector = (state: StoreState) => state.announcements.meta
export const announcementsModelsSelector = (state: StoreState) => state.announcements.models
export const announcementsModelsMetaSelector = (state: StoreState) => state.announcements.models_meta
const rawAnnouncementsIdsListSelector = (state: StoreState) => state.announcements.list
export const announcementsHiddenSelector = (state: StoreState) => state.announcements.hidden
export const announcementsIdsListSelector = createSelector([rawAnnouncementsIdsListSelector, announcementsHiddenSelector], (list, hidden) => {
  return list.filter(id => !hidden[id])
})

export const announcementByIdSelectorActor = (
  id: string | null,
  announcements: ReturnType<typeof announcementsModelsSelector>,
  announcements_meta: ReturnType<typeof announcementsModelsMetaSelector>,
  locations: ReturnType<typeof locationsSelector>,
  announcementResponses: ReturnType<typeof announcementResponsesSelector>,
  users: ReturnType<typeof usersSelector>
): AnnouncementCardBundle | null => {
  if (!id) return null
  const announcement = announcements[id]

  if (!announcement) return null
  const meta = announcements_meta[announcement.id] || defaultAnnouncementMeta

  if (!announcement.relationships.location || !announcement.relationships.location.data) return null
  const location = locations[announcement.relationships.location.data.id]
  if (!location) return null

  const parent = announcement.relationships.parent && announcement.relationships.parent.data ? users[announcement.relationships.parent.data.id] : null

  if (!announcement.relationships.responses || !announcement.relationships.responses.data) return null
  const responses = announcement.relationships.responses.data.map(r => {
    const response = announcementResponses[r.id]
    if (!response) return null

    if (!response.relationships.sitter || !response.relationships.sitter.data)
      return {
        response,
        parent,
        sitter: null,
        location,
        announcement,
      }

    const sitter = users[response.relationships.sitter.data.id]
    if (!sitter) return null

    return {
      response,
      parent,
      sitter,
      location,
      announcement,
    }
  })
  if (responses.indexOf(null) !== -1) return null

  const activeResponse = responses.find(r => r!.response.attributes.status === 'accepted')!
  const sitter = activeResponse ? activeResponse.sitter : null

  return {
    announcement,
    meta,
    location,
    parent,
    sitter,
    responses: responses as Exclude<(typeof responses)[0], null>[],
  }
}

export const announcementsListSelector = createSelector(
  [announcementsIdsListSelector, announcementsModelsSelector, announcementsModelsMetaSelector, locationsSelector, announcementResponsesSelector, usersSelector],
  (list, announcements, announcements_meta, locations, announcementResponses, users) =>
    list.map(id => announcementByIdSelectorActor(id, announcements, announcements_meta, locations, announcementResponses, users)!).filter(x => x)
)

export const makeAnnouncementByIdSelector = (announcementIdSelector: (state: StoreState, props?: any) => string | null) =>
  createSelector(
    [announcementIdSelector, announcementsModelsSelector, announcementsModelsMetaSelector, locationsSelector, announcementResponsesSelector, usersSelector],
    announcementByIdSelectorActor
  )

export type AnnouncementSelectorModel = Exclude<ReturnType<ReturnType<typeof makeAnnouncementByIdSelector>>, null>

export const announcementsCompleteSelector = createSelector(announcementsMetaSelector, meta => meta.cursor === null)

export const announcementCountSelector = createSelector([announcementsIdsListSelector], list => list.length)

export const announcementsSearchAddressSelector = createSelector(
  [profileSitterAddressSelector, profilePlaceAddressSelector],
  (sitterAddress, profilePlaceAddres) => {
    return sitterAddress ?? profilePlaceAddres
  }
)
