import { combineReducers } from 'redux'

import avatar_upload from './profile.avatar_upload'
import avatarIds from './profile.avatarIds'
import meta from './profile.meta'
import place from './profile.place'
import pushNotifications from './profile.push_notifications'
import update from './profile.update'
import user from './profile.user'

export default combineReducers({
  user,
  meta,
  avatarIds,
  avatar_upload,
  update,
  place,
  pushNotifications,
})
