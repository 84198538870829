import moment from '@app/utils/moment'

import { newsLoadingMeta, newsStateMeta } from '@app/store/selectors/news'
import { createThunk } from '@app/store/thunk'

import { getNews, postNewsRead } from './api/news'

export const fetchInitialNews = () =>
  createThunk(async dispatch => {
    newsLoadingMeta.set({ loading: true })
    const resp = await dispatch(getNews({ page: 1, per_page: 10 }))
    if (resp && !resp.error) {
      dispatch(newsStateMeta.set({ loadedAt: moment().unix(), page: resp.payload.meta.current_page, total_pages: resp.payload.meta.total_pages }))
    }
    return resp
  })

export function fetchNextNews() {
  return createThunk(async dispatch => {
    const meta = dispatch(newsStateMeta.get())
    if (dispatch(newsLoadingMeta.get())?.loading) return
    if (!meta) return
    if (meta.page >= meta.total_pages) return
    const page = meta.page + 1
    dispatch(newsLoadingMeta.set({ loading: true }))
    const resp = await dispatch(getNews({ page, per_page: 10 }))
    dispatch(newsLoadingMeta.set({ loading: false }))
    if (resp && !resp.error) {
      dispatch(newsStateMeta.set({ ...meta, page: resp.payload.meta.current_page, total_pages: resp.payload.meta.total_pages }))
    }
    return resp
  })
}

export function newsMarkAsRead() {
  return createThunk(async (dispatch, getState) => {
    const state = getState()
    // emulate "mark as read" in supervise mode
    if (state.session.supervisor || state.session.supervisor) {
      dispatch(postNewsRead.fulfill(undefined))
      return
    }

    return await dispatch(postNewsRead())
  })
}
